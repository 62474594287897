import React from 'react';
import { Link } from 'react-router-dom';
import "../pages/Projects.css";

function CardItem(props) {
  return (
    <>
      <li className='cards__item'>
        <Link to={props.path} target='_blank' className='cards__item__link'>
          <figure className='cards__item__pic-wrap'>
            <img
              className='cards__item__img'
              alt='projectImg'
              src={props.src}
            />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
            <p>{props.label}</p>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItem;