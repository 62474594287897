import React, { useState } from "react";
import "./Login.css";
import axios from "axios";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const crazysoziety = "https://crazysoziety.net";

    const handleSubmit = (e) => {
        e.preventDefault();

        const bodyData = {
            username: username,
            password: password
        };

        axios.post(crazysoziety + `/api/profile/login`, bodyData)
        .then((response) => {
            const data = response.data;
            if (data.token) {
                localStorage.setItem("jwt", data.token);
                window.location.href = "/";
            } else {
                console.error('Error:', data.error);
            }
        })
        .catch((error) => {
            console.error('Caught error:', error);
        });
    }

    return (
        <div className="Login">
            <form onSubmit={handleSubmit}>
                <label>
                    Username:
                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} /> 
                </label>
                <label>
                    Password:
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />     
                </label>
                
                <hr />
                <button className="submitButton" type="submit">login</button>
                <span>No account? <a href="/profile/start">Click here!</a></span>
            </form>
        </div>
    )
}


export default Login;