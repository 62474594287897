import React from "react";
import "./Projects.css";

import CardItem from "../components/CardItem";
import Youtube from "../assets/thumbnail.jpg";

const Projects = () => {


    return (
        <div className="Projects">
            <div className='workContainer'>
                <div className='workWrapper'>
                    <ul className='workItems'>
                        <CardItem
                            src={Youtube}
                            text='Youtube'
                            path='https://youtube.crazysoziety.net'
                            label='#MongoDB #Express #React #JavaScript #NodeJs'
                        />
                    </ul>
                </div>
            </div>
        </div>
    )
}


export default Projects;