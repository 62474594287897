import React, { useEffect, useState } from "react";
import "./About.css";

const About = () => {
    const [currentItem, setCurrentItem] = useState(null);

    useEffect(() => {
        const liElements = document.querySelectorAll(".missionLi");
        
        liElements.forEach((li, index) => {
          setTimeout(() => {
            li.classList.add("show");
          }, index * 1000);
        });
    }, []);

    const handleItemClick = (index) => {
        if (currentItem === index) {
            setCurrentItem(null);
        } else {
            setCurrentItem(index);
        }
    }




    return (
        <div className="About">
            <div className="header">
                <h1 style={{ fontSize: "40px" }}>My Mission</h1>
            </div>
            <div>
                <ul className="missionUl">
                    <li onClick={() => handleItemClick(0)} className="missionLi">
                        <div className="itemDiv">
                            Developement <ion-icon name={currentItem === 0 ? "chevron-up-outline" : "chevron-down-outline"}></ion-icon>
                        </div>
                        <div className={`collapseContent ${currentItem === 0 ? "show" : ""}`}>
                        In today's digital age, software development isn't just a technical endeavor—it's an art. It's the canvas where visionaries breathe life into their most groundbreaking ideas. My journey in development has ignited a passion: to one day launch a significant, impactful project of my own.
                        From writing my first lines of code to constructing complex systems, a dream has grown within me. A project that's not just technologically advanced but truly transformative.
                        The path towards this vision isn't without challenges, but it's these very obstacles that fuel my drive. They push me to think bigger, learn continuously, and refine my skills. With every line of code, I move closer to that goal. For me, software development isn't just a job—it's a calling. And someday, I'll look back with pride at the monumental project I've brought to life.
                        </div>
                    </li>
                    <li onClick={() => handleItemClick(1)} className="missionLi">
                        <div className="itemDiv">
                            Robotics <ion-icon name={currentItem === 1 ? "chevron-up-outline" : "chevron-down-outline"}></ion-icon>
                        </div>
                        <div className={`collapseContent ${currentItem === 1 ? "show" : ""}`}>
                        The world of robotics is a fascinating blend of engineering and imagination. For me, it represents the frontier of what's possible when human creativity meets technological precision. My fascination with this domain was amplified when I first encountered Arduino robots. Their simplicity, combined with immense potential, drew me in like a moth to a flame.
                        I've since taken this passion a step further, crafting my own small rover. Every wheel alignment, sensor placement, and line of code was a testament to my dedication and vision for robotics. This rover might be diminutive in stature, but it embodies the colossal dreams I hold for the future of robotics.
                        With every creation, I aim to push the boundaries, to explore what's possible, and to bring my robotic visions to life. Robotics, for me, is more than just machines and algorithms—it's the future, and I'm thrilled to be a part of shaping it.
                        </div>
                    </li>
                    <li onClick={() => handleItemClick(2)} className="missionLi">
                        <div className="itemDiv">
                            Experience <ion-icon name={currentItem === 2 ? "chevron-up-outline" : "chevron-down-outline"}></ion-icon>
                        </div>
                        <div className={`collapseContent ${currentItem === 2 ? "show" : ""}`}>
                        The journey of mastering the art of coding is both challenging and exhilarating. My foundational knowledge and skills were honed at Codecool, an institution that transformed me from an eager novice to a confident coder. At Codecool, I was introduced to a myriad of technologies, each unlocking a new dimension of digital creation.
                        From building dynamic web applications using Express, Node.js, and React to managing data with MongoDB, SQL, and PostgreSQL, every module was a deep dive into the vast ocean of programming. With hands-on projects, I mastered the intricacies of JavaScript, further streamlined with Java's efficiency, and gave life to stunning user interfaces with CSS.
                        This rigorous training not only equipped me with a diverse skill set but also instilled a mindset of continuous learning. My time at Codecool was more than just an education—it was an experience that laid the foundation for my future endeavors in the tech world.
                        </div>
                    </li>
                    <li onClick={() => handleItemClick(3)} className="missionLi">
                        <div className="itemDiv">
                            Testemonials <ion-icon name={currentItem === 3 ? "chevron-up-outline" : "chevron-down-outline"}></ion-icon>
                        </div>
                        <div className={`collapseContent ${currentItem === 3 ? "show" : ""}`}>
                            <div className="testimonialCard">
                                <div className="testimonialContent">
                                    "Never give you up. Never let you down."
                                </div>
                                <div className="testimonialAuthor">
                                    - Rick Astley
                                </div>
                            </div>
                            <div className="testimonialCard">
                                <div className="testimonialContent">
                                    "Shrek 4 was underrated." 
                                </div>
                                <div className="testimonialAuthor">
                                    - Harambe
                                </div>
                            </div>
                            <div className="testimonialCard">
                                <div className="testimonialContent">
                                    "This Website is my biggest Sin."
                                </div>
                                <div className="testimonialAuthor">
                                    - Jesus Christ
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default About;