import React, { useState, useEffect } from "react";
import "./Layout.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Layout = () => {

    const navigate = useNavigate();
    const token = localStorage.getItem("jwt");

    const [user, setUser] = useState("");
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const crazysoziety = "https://crazysoziety.net";

    useEffect(() => {
        const token = localStorage.getItem("jwt");
        
        if (token) {
            axios.get(crazysoziety + "/api/profile/", {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            .then(response => {
                const data = response.data;
                setUser(data.username);
            });  
        }

    }, []);

    return (
        <div className="Layout">
            <nav className={isMenuOpen ? 'open' : ''}>
                <ul>
                    {token ? (
                        <li onClick={() => {setIsMenuOpen(false); navigate(`/profile/${user}`)}} className="profileLink">{user} <ion-icon style={{color: "white"}} name="person-outline" /></li>
                    ) : (
                        <li onClick={() => {setIsMenuOpen(false); navigate("/profile/login")}} className="profileLink">Profile <ion-icon style={{color: "white"}} name="person-outline" /></li>
                    )}

                    <hr />
                    <li onClick={() => {setIsMenuOpen(false); navigate("/")}}>Home <ion-icon style={{color: "white"}} name="home-outline"></ion-icon></li>
                    <li onClick={() => {setIsMenuOpen(false); navigate("/about")}}>About <ion-icon style={{color: "white"}} name="chatbubbles-outline"></ion-icon></li>
                    <li onClick={() => {setIsMenuOpen(false); navigate("/projects")}}>Projects <ion-icon style={{color: "white"}} name="documents-outline"></ion-icon></li>
                </ul>
            </nav>

            <div className="mobileMenuIcon" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <ion-icon style={{ color: "white" }} name={isMenuOpen ? "close-outline" : "menu-outline"}></ion-icon>
            </div>
        </div>
    )
}


export default Layout;