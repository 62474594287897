import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Profile.css";

const Profile = () => {

    const [user, setUser] = useState("");
    const [showEditForm, setShowEditForm] = useState(false);
    const [showSourceCodes, setShowSourceCodes] = useState(false);
    const [newUsername, setNewUsername] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const crazysoziety = "https://crazysoziety.net";

    useEffect(() => {
        const token = localStorage.getItem("jwt");
        if (token) {
            axios.get(crazysoziety + "/api/profile/", {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            .then(response => {
                const data = response.data;
                setUser(data.username);
            });
        }
    }, []);

    const handleEdit = (e) => {
        e.preventDefault();

        const bodyData = {
            newUsername: newUsername,
            newPassword: newPassword
        }

        axios.patch(crazysoziety + "/api/edit/profile/", bodyData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        })
        .then((response) => {
            const data = response.data;
            if (data) {
                window.location.href = "/";
            } else {
                console.error('Error:', data.error);
            }
        })
        .catch((error) => {
            console.error('Caught error:', error);
        });
        
    }
    

    return (
        <div className="Profile">
            <div className="header">
                <h1 style={{ fontSize: "40px" }}>Welcome, {user}!</h1>
                <div className="profileButtons">
                    <button className="logoutButton" onClick={() => { 
                        localStorage.removeItem("jwt"); 
                        window.location.href = "/profile/login"; 
                    }}>Log Out</button>

                    <button className="logoutButton" onClick={() => { 
                        setShowEditForm(true);
                        setShowSourceCodes(false);
                    }}>Edit Profile</button>

                    <button className="logoutButton" onClick={() => { 
                        setShowEditForm(false);
                        setShowSourceCodes(true); 
                    }}>View Source Code</button>
                </div>


            </div>
            <div className="contentContainer">
                <form onSubmit={handleEdit} className={`editForm ${showEditForm ? 'show' : ''}`}>
                    <input 
                        value={newUsername}
                        onChange={(e) => setNewUsername(e.target.value)}
                        type="text" 
                        placeholder="New Username" 
                    />
                    <input 
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        type="password" 
                        placeholder="New Password" 
                    />
                    <button type="submit">Update</button>
                </form>

                <div className={`sourceCodes ${showSourceCodes ? 'show' : ''}`}>
                    <ul>
                        <li className="sourceCode">
                            <a target="_blank" style={{ textDecoration: "none", color: "white", display: "flex", alignItems: "center", gap: "14px" }} href="https://github.com/itsKiiro/youtube" rel="noreferrer">
                                Youtube 
                                <ion-icon name="logo-github"></ion-icon>  
                            </a>
                        </li>
                    </ul>
                </div>
            </div>


            
        </div>
    )
}


export default Profile;