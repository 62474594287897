import React, { useState } from "react";
import "./Start.css";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const Start = () => {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const crazysoziety = "https://crazysoziety.net";

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        const formBody = {
            username: username,
            email: email,
            password: password
        };

        axios.post(crazysoziety + `/api/profile/start/`, formBody)
        .then((response) => {
            if (response.status === 200) {
                navigate("/profile/login");
            } else {
                alert("Fehler bei der Registrierung!");
            }
        })
        .catch((error) => {
            console.error('Caught error:', error);
        });


    }

    return (
        <div className="Start">
            <form onSubmit={handleSubmit}>
                <label>
                    Username:
                    <input required type="text" value={username} onChange={(e) => setUsername(e.target.value)} /> 
                </label>
                <label>
                    Email:
                    <input required type="email" value={email} onChange={(e) => setEmail(e.target.value)} />     
                </label>
                <label>
                    Password:
                    <input required type="password" value={password} onChange={(e) => setPassword(e.target.value)} />     
                </label>
                
                <hr />
                <button className="submitButton" type="submit">Start</button>
                <span>Already registered? <a href="/profile/login">Sign in!</a></span>
            </form>
        </div>
    )
}


export default Start;