import React, { useState } from "react";
import "./Home.css";
import emailjs from "emailjs-com";

const Home = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const SERVICE_ID = "service_n4r3y42"
    const TEMPLATE_ID = "template_5uybqzg";
    const USER_ID = "CrZGU0G7vp0L_8udG";

    const handleSubmit = (e) => {
        e.preventDefault();

        const mail = {
            from_name: name,
            email_id: email,
            message: message
        }

        try {
            emailjs.send(SERVICE_ID, TEMPLATE_ID, mail, USER_ID)
              .then((result) => {
                  if (result) {
                    alert("Thx for the Message!");
                    setName("");
                    setEmail("");
                    setMessage("");
                  } else {
                    alert("Error! Try again in few minutes.");
                  }
              })
        } catch (error) {
        alert("Fehler! Versuche es in paar Minuten wieder.");
        console.log(error);
        }
    }


    return (
        <div className="Home">
            <div className="header">
                <h1>CrazySoziety.net</h1>
                <p>Requiescat in pace Harambe</p>
            </div>
            <div className="social">
                <div style={{ color: "white" }}>
                    <h2>Social</h2>
                </div>
                <div className="socialLinks">
                    <a target="_blank" rel="noreferrer" href="https://instagram.com/marcel_revo"><ion-icon style={{ color: "white" }} name="logo-instagram" /></a>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/marcel-karall-657705272/"><ion-icon style={{ color: "white" }} name="logo-linkedin" /></a>
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/marcel.karall.1/"><ion-icon style={{ color: "white" }} name="logo-facebook" /></a>
                    <a target="_blank" rel="noreferrer" href="https://github.com/itsKiiro"><ion-icon style={{ color: "white" }} name="logo-github" /></a>
                </div>
            </div>

            <div className="infoBox">
                <h2>Marcel Karall <span className="rotateIcon"><ion-icon style={{ fontSize: "40px" }} name="logo-nodejs"></ion-icon></span></h2>
                <h4>Web developer</h4>
                <form onSubmit={handleSubmit}>
                    <h3>
                        Get in touch 
                        <span>
                            <ion-icon name="globe-outline"></ion-icon>
                        </span>
                        <button>
                            Send Message
                        </button>
                    </h3>
                    <input 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        placeholder="Name"
                    />
                    <input 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="Email"
                    />
                    <textarea 
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        placeholder="Message"
                    />
                </form>
            </div>
        </div>
    );
}


export default Home;